import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WasteCalendarDate } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WasteCalendarItemsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getUpcomingItems(postalCode: string, houseNumber: number, addition: string = '', take: number = 3) {
    return this.http.get<WasteCalendarDate[]>(
      `${this.BASE_URL}api/waste-calendar/upcoming?postalcode=${postalCode}&houseNumber=${houseNumber}&addition=${
        addition || ''
      }&take=${take}`
    );
  }

  getItemsYear(postalCode: string, houseNumber: number, addition: string = '', year: number = 2023) {
    return this.http.get<WasteCalendarDate[]>(
      `${this.BASE_URL}api/waste-calendar/year?postalcode=${postalCode}&houseNumber=${houseNumber}&addition=${addition || ''}&year=${year}`
    );
  }
}
