import * as fromWasteCalendarItemss from './waste-calendar-items.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'WasteCalendar';

export interface WasteCalendarState {
  wasteCalendarItems: fromWasteCalendarItemss.WasteCalendarItemsState;
}

export const reducers: ActionReducerMap<WasteCalendarState> = {
  wasteCalendarItems: fromWasteCalendarItemss.WasteCalendarItemReducer,
};

export const getWasteCalendarState = createFeatureSelector<WasteCalendarState>(featureKey);

export const metaReducers: MetaReducer<WasteCalendarState>[] = !environment.production ? [] : [];
