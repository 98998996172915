import { inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromAddressesStore from '../../addresses/store';
import { of } from 'rxjs';
import { CanActivateFn, ActivatedRouteSnapshot } from '@angular/router';

export function WasteCalendarGuard(items: number = 5): CanActivateFn {
  return () => {
    const store = inject(Store);
    return store
      .select(fromStore.getWasteCalendarItemsLoaded)
      .pipe(
        withLatestFrom(store.select(fromAddressesStore.getAddress)),
        tap(([loadedUpcomming, address]) => {
          if (!loadedUpcomming) {
            store.dispatch(
              fromStore.LoadUpcomingCalendarItems({
                postalCode: address?.postalCodeFull!,
                houseNumber: +address?.houseNumber!,
                addition: address?.addition,
                take: items,
              })
            );
          }
        }),
        filter(([loadedUpcomming, address]) => loadedUpcomming),
        take(1)
      )
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  };
}

export const WasteCalendarYearGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const year = route.params['year'] || new Date().getFullYear();
  const store = inject(Store);
  return store
    .select(fromStore.getWasteCalendarYearItemsLoaded)
    .pipe(
      withLatestFrom(store.select(fromStore.getWasteCalendarYearItemsLoading), store.select(fromAddressesStore.getAddress)),
      tap(([loadedYear, loadingYear, address]) => {
        // console.log(loadedYear, loadedYear);
        if (!loadedYear.some(a => a === year) && !loadingYear.some(a => a === year)) {
          store.dispatch(
            fromStore.LoadWasteCalendarYearItems({
              postalCode: address?.postalCodeFull!,
              houseNumber: +address?.houseNumber!,
              addition: address?.addition,
              year: year,
            })
          );
        }
      }),
      map(([loadedYear, loadingYear, address]) => loadedYear.some(a => a === year)),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
