<table class="calendar__container mt-5 w-100">
  <thead>
    <tr>
      <td *ngFor="let day of dates[0]" class="py-3">
        <span class="d-none d-lg-inline fw-bold text-secondary">{{ day.date | date: 'EEEE' }}</span>
        <span class="d-lg-none fw-bold text-secondary">{{ day.date | date: 'EE' }}</span>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let week of dates" class="calendar__week">
      <td
        [class.is--disabled-day]="!day.active"
        [class.is--today]="day.today"
        [ngClass]="{ 'has--day-items align-baseline': day.items!.length >= 2 }"
        *ngFor="let day of week"
        class="p-1 position-relative calendar-cell"
        [class]="getFirstCellClass(day)"
      >
        <!-- <span class="trash-icon__marker chip chip--xxs chip--danger chip-outline" *ngIf="dayHasIrregular(day.items || [])">
          <fstr-icon anchor="icon-alert-warning"></fstr-icon>
        </span> -->
        <span [ngClass]="{ 'text-light fw-bold': day.items?.length }" class="calendar-cell__label">
          {{ day.date | date: 'd' }}
        </span>
        <div class="calendar-cell__trash-item" *ngFor="let item of day.items" [ngClass]="backgroundClass(item)">
          <fstr-icon [anchor]="'icon-' + item.wasteType.icon" [title]="item.wasteType.title"></fstr-icon>
        </div>
      </td>
    </tr>
  </tbody>
</table>
