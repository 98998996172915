<!--<p>waste-calendar-item works!</p>
<pre>{{ item | json }}</pre>-->

<article class="waste-calendar-item" [class.is--active]="isToday || (isTommorow && !item.gripLocation)">
  <figure class="trash-icon" [ngClass]="backgroundClass">
    <span class="trash-icon__marker chip chip--xxs chip--danger chip-outline" *ngIf="item.isIrregular">
      <fstr-icon anchor="icon-alert-warning"></fstr-icon>
    </span>
    <fstr-icon [anchor]="'icon-' + item.wasteType.icon"></fstr-icon>
  </figure>
  <main class="waste-calendar-item__main">
    <div class="lh-sm">
      <span>
        {{ item.wasteType.title }}

        @if (item.gripLocation) {
          <a
            [routerLink]="['/', 'pagina', '2065', 'Grondstoffen-Inleverpunt-GRIPwagen']"
            [matTooltip]="'app-instellingen-meldingen-grip-tooltip-tekst' | fromDictionary"
            class="text-secondary"
          >
            <fstr-icon class="icon-xxs fill-secondary" anchor="icon-question-small"></fstr-icon>
          </a>
        }
      </span>
      @if (item.isIrregular) {
        <span class="type-body-xxs fw-bold text-danger d-inline-block w-100">
          <span class="d-none d-md-inline">{{ 'inzamelkalender-gewijzigde-datum-bericht-tekst' | fromDictionary }}</span>
          <span class="d-md-none">Gewijzigde datum</span>
        </span>
      } @else if (item.gripLocation) {
        <span class="type-body-xxs fw-bold text-success d-inline-block w-100">
          <span>{{ item.gripLocation.title }}</span>
        </span>
      }
    </div>
    <span class="fw-bold text-secondary text-end d-flex flex-column">
      <ng-container *ngIf="!isToday && !isTommorow">{{ item.date | date: (isThisYear ? 'EEEE d MMM' : 'EEEE d MMM y') }}</ng-container>
      <ng-container *ngIf="isToday">Vandaag</ng-container>
      <ng-container *ngIf="isTommorow">Morgen</ng-container>
      @if (item.gripLocation?.nextDate?.openingHours) {
        <span class="text-muted type-body-xxs fw-semi grip-opening-hours">{{ item.gripLocation?.nextDate?.openingHours }}</span>
      }
    </span>
  </main>
</article>
