import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { addDays, endOfMonth, endOfWeek, getMonth, startOfMonth, startOfWeek, isSameDay, getDay } from 'date-fns';
import { WasteCalendarDate, WasteCalendarViewDate } from '../../models';
import { getGarbageTypeIcon } from '../../config/garbage-type-mappings';

@Component({
  selector: 'app-waste-calendar-grid',
  templateUrl: './waste-calendar-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WasteCalendarGridComponent {
  @Input({ required: true }) date!: Date;
  @Input({ required: true }) items: WasteCalendarDate[] = [];

  get dates() {
    const startOfTheSelectedMonth = startOfMonth(this.date);
    const endOfTheSelectedMonth = endOfMonth(this.date);
    const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });
    const endDate = endOfWeek(endOfTheSelectedMonth, { weekStartsOn: 1 });

    let currentDate = startDate;

    let allWeeks: WasteCalendarViewDate[][] = [];

    while (currentDate <= endDate) {
      allWeeks = [...allWeeks, this.generateDatesForWeek(currentDate)];
      currentDate = addDays(currentDate, 7);
    }

    return allWeeks;
  }

  private generateDatesForWeek(start: Date) {
    let week: WasteCalendarViewDate[] = [];
    for (let day = 0; day < 7; day++) {
      const d = addDays(start, day);
      const dateItem = {
        date: addDays(start, day),
        active: getMonth(d) === getMonth(this.date) && getDay(d) !== 0,
        today: isSameDay(d, new Date()),
        items: this.items.filter(a => isSameDay(new Date(a.date), d)),
      };
      week = [...week, dateItem];
    }
    return week;
  }

  getFirstCellClass(item: WasteCalendarViewDate) {
    // return item.items?.map(a => `bg-trash-${a.garbageTypeCode.toLowerCase()}`).join(' ');
    // return `bg-trash-${item.items?.[0]?.wasteType.code?.toLowerCase()}`;
    if (item.items?.length === 0 || !item.items?.[0]) return '';
    return this.backgroundClass(item.items?.[0]);
  }
  dayHasIrregular(items: WasteCalendarDate[]) {
    return items.some(item => item.isIrregular);
  }

  backgroundClass(item: WasteCalendarDate) {
    return `bg-trash-${getGarbageTypeIcon(item.wasteType.code)}`;
  }
}
