import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WasteCalendarDate } from '../../models';
import { getGarbageTypeIcon } from '../../config/garbage-type-mappings';
import { addDays, isSameDay, isSameYear } from 'date-fns';

@Component({
  selector: 'app-waste-calendar-item',
  templateUrl: './waste-calendar-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WasteCalendarItemComponent {
  @Input({ required: true }) item!: WasteCalendarDate;

  get isToday() {
    return isSameDay(new Date(this.item.date), new Date());
  }

  get isTommorow() {
    return isSameDay(new Date(this.item.date), addDays(new Date(), 1));
  }

  get isThisYear() {
    return isSameYear(new Date(this.item.date), new Date());
  }
  get backgroundClass() {
    return `bg-trash-${getGarbageTypeIcon(this.item.wasteType.code)}`;
  }
}
