import { createAction, props } from '@ngrx/store';
import { WasteCalendarDate } from '../../models';

export const LoadUpcomingCalendarItems = createAction(
  '[Waste Calendar] Load Upcoming Waste Calendar',
  props<{ postalCode: string; houseNumber: number; addition?: string; take?: number }>()
);
export const LoadWasteCalendarItemsSuccess = createAction(
  '[Waste Calendar] Load Upcoming Waste Calendar Success',
  props<{ dates: WasteCalendarDate[] }>()
);
export const LoadWasteCalendarItemsFail = createAction('[Waste Calendar] Load Upcoming Waste Calendar Fail', props<{ error: any }>());

export const ResetWasteCalendarItems = createAction('[Waste Calendar] Reset Waste Calendar');

export const LoadWasteCalendarYearItems = createAction(
  '[Waste Calendar] Load Waste Calendar',
  props<{ postalCode: string; houseNumber: number; addition?: string; year?: number }>()
);
export const LoadWasteCalendarYearItemsSuccess = createAction(
  '[Waste Calendar] Load Waste Calendar Success',
  props<{ dates: WasteCalendarDate[]; year?: number }>()
);
export const LoadWasteCalendarYearItemsFail = createAction(
  '[Waste Calendar] Load Waste Calendar Fail',
  props<{ error: any; year?: number }>()
);
