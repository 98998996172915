import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as storyActions from '../actions/waste-calendar-items.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { WasteCalendarItemsService } from '../../services';

import * as fromAddresses from '../../../addresses/store';

export const LoadWasteCalendarItems$ = createEffect(
  (actions$ = inject(Actions), service = inject(WasteCalendarItemsService)) => {
    return actions$.pipe(
      ofType(storyActions.LoadUpcomingCalendarItems),
      switchMap(({ postalCode, houseNumber, addition, take }) =>
        service.getUpcomingItems(postalCode, houseNumber, addition, take).pipe(
          map(dates => storyActions.LoadWasteCalendarItemsSuccess({ dates })),
          catchError(error => of(storyActions.LoadWasteCalendarItemsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadCalendarItems$ = createEffect(
  (actions$ = inject(Actions), service = inject(WasteCalendarItemsService)) => {
    return actions$.pipe(
      ofType(storyActions.LoadWasteCalendarYearItems),
      switchMap(({ postalCode, houseNumber, addition, year }) =>
        service.getItemsYear(postalCode, houseNumber, addition, year).pipe(
          map(dates => storyActions.LoadWasteCalendarItemsSuccess({ dates })),
          catchError(error => of(storyActions.LoadWasteCalendarItemsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const ResetCalendar$ = createEffect(
  (actions$ = inject(Actions), service = inject(WasteCalendarItemsService)) => {
    return actions$.pipe(
      ofType(fromAddresses.ClearAddress),
      map(() =>
        storyActions.ResetWasteCalendarItems()
      )
    );
  },
  { functional: true }
);
