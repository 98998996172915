import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { WasteCalendarItemComponent } from './components/waste-calendar-item/waste-calendar-item.component';
import { WasteCalendarGridComponent } from './components/waste-calendar-grid/waste-calendar-grid.component';
import { WasteCalendarComponent } from './containers/waste-calendar/waste-calendar.component';
import { CoreModule } from '../core/core.module';
import { WasteCalendarRoutingModule } from './waste-calendar-routing.module';
import { InsightModule } from '../insight/insight.module';
import { AlertComponent } from '../standalone/alert/alert.component';
import { AddressesModule } from '../addresses/addresses.module';
import { TipModule } from '../tips/tip.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [WasteCalendarItemComponent, WasteCalendarGridComponent, WasteCalendarComponent],
  imports: [
    CommonModule,
    IconModule,
    TextUtilityModule,
    DictionaryNgrxModule,
    CoreModule,
    AlertComponent,
    WasteCalendarRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    AddressesModule,
    MatInputModule,
    MatMenuModule,
    TipModule,
    MatTooltipModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
    InsightModule,
  ],
  exports: [WasteCalendarItemComponent, WasteCalendarGridComponent],
})
export class WasteCalendarModule {}
