import { createReducer, on } from '@ngrx/store';
import { WasteCalendarDate } from '../../models';
import * as pageActions from '../actions/waste-calendar-items.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface WasteCalendarItemsState {
  entities: { [id: number]: WasteCalendarDate };
  loadedUpcoming: boolean;
  loadingUpcoming: boolean;
  loadedYear: number[];
  loadingYear: number[];
  error?: any;
}

export const initialState: WasteCalendarItemsState = {
  entities: {},
  loadedUpcoming: false,
  loadingUpcoming: false,
  loadedYear: [],
  loadingYear: [],
};

export const WasteCalendarItemReducer = createReducer(
  initialState,
  on(pageActions.LoadUpcomingCalendarItems, (state: WasteCalendarItemsState) => {
    return {
      ...state,
      entities: {},
      loadingUpcoming: true,
      loadedUpcoming: false,
    };
  }),
  on(pageActions.LoadWasteCalendarItemsSuccess, (state: WasteCalendarItemsState, { dates }) => {
    const entities = dates ? ToEntities(dates, 'id', state.entities) : state.entities;

    return {
      ...state,
      entities,
      loadingUpcoming: false,
      loadedUpcoming: true,
      error: null,
    };
  }),
  on(pageActions.LoadWasteCalendarItemsFail, (state: WasteCalendarItemsState, { error }) => {
    return {
      ...state,
      loadingUpcoming: false,
      loadedUpcoming: false,
      error,
    };
  }),
  on(pageActions.LoadWasteCalendarYearItems, (state: WasteCalendarItemsState, { year }) => {
    const y = year || new Date().getFullYear();
    return {
      ...state,
      loading: [...state.loadingYear, y],
    };
  }),
  on(pageActions.LoadWasteCalendarYearItemsSuccess, (state: WasteCalendarItemsState, { dates, year }) => {
    const y = year || new Date().getFullYear();
    const entities = ToEntities(dates, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: state.loadingYear.filter(a => a !== y),
      loaded: [...state.loadedYear, y],
    };
  }),
  on(pageActions.LoadWasteCalendarYearItemsFail, (state: WasteCalendarItemsState, { error, year }) => {
    const y = year || new Date().getFullYear();
    return {
      ...state,
      loading: state.loadingYear.filter(a => a !== y),
      loaded: state.loadedYear.filter(a => a !== y),
      error,
    };
  }),
  on(pageActions.ResetWasteCalendarItems, (state: WasteCalendarItemsState) => {
    return {
      ...initialState,
    };
  })
);

export const getWasteCalendarItemsEntities = (state: WasteCalendarItemsState) => state.entities;
export const getWasteCalendarItemsLoading = (state: WasteCalendarItemsState) => state.loadingUpcoming;
export const getWasteCalendarItemsLoaded = (state: WasteCalendarItemsState) => state.loadedUpcoming;
export const getWasteCalendarYearItemsLoading = (state: WasteCalendarItemsState) => state.loadingYear;
export const getWasteCalendarYearItemsLoaded = (state: WasteCalendarItemsState) => state.loadedYear;
export const getWasteCalendarItemsError = (state: WasteCalendarItemsState) => state.error;
